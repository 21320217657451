import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(InfiniteLoading, {
    props : {
        spinner: 'waveDots',
    },
    slots: {
        noResults: 'Поки що це все новини',
        noMore: 'Поки що це все новини',
        error: 'Щось пішло не так :(',
        errorBtnText: 'Оновити'
    }
})