import Vue from 'vue'

const showContentImageObserver = new IntersectionObserver(
    (entries, showContentImageObserver) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !entry.target.classList.contains('loaded')) {
        
                let path = entry.target.getAttribute('data-image'),
                    size = entry.target.getAttribute('data-size'),
                    folder = document.querySelector('[data-folder]').getAttribute('data-folder');

                if (folder) {
                    path += '/' + folder;
                }

                let image = new Image();  
                image.onload = function() {
                    // console.log(this.width, this.height);
                    entry.target.style.backgroundImage = `url(${path})`;
                    entry.target.style.backgroundSize = size ? size : 'cover';
                    entry.target.classList.add('loaded');

                    if (size == 'contain') {
                        let parent = entry.target.closest('.article__images');
                        console.log(parent.clientWidth, parent.clientHeight);
                        console.log(parent.querySelectorAll('.article__image').length);
                        console.log(this.width / parent.clientWidth);
                        if (parent.querySelectorAll('.article__image').length == 1) {
                            let delta = parent.clientHeight - entry.target.clientHeight;
                            let newHeight = Math.round(this.height * (entry.target.clientWidth / this.width) + delta);
                            parent.style.height = newHeight + 'px';

                            if (entry.target.clientWidth <= 768) {
                                parent.querySelector('.article__image-wrap').style.height = newHeight + 'px';
                            }
                        }
                    }
                } 
                image.src = path; 
    
                showContentImageObserver.unobserve(entry.target)
            }
        })
    }
)

Vue.directive('show-content-image', {
    bind: (el) => { 
        let images = el.querySelectorAll('[data-image]')
        for (let index = 0; index < images.length; index++) {
            showContentImageObserver.observe(images[index])
        }
    }
})