export const state = () => ({
    categories: []
})
  
export const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
    }
}

export const actions = {
    async getCategories({commit}) {
        const data = await this.$axios.$get(`/api/v1/app/categories`, {
            params: {
                page: 1,
                status: 'visible'
            }
        })
        commit('setCategories', data.data)

        return data.data;
    }
}

export const getters = {
    categories(state) {
        return state.categories;
    }
}
