import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _434f34ee = () => interopDefault(import('../pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _3c6b12cc = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _2d1ac5d8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _ce5099d8 = () => interopDefault(import('../pages/author/_uri.vue' /* webpackChunkName: "pages/author/_uri" */))
const _07bfe241 = () => interopDefault(import('../pages/category/_uri.vue' /* webpackChunkName: "pages/category/_uri" */))
const _7be8518c = () => interopDefault(import('../pages/news/_uri.vue' /* webpackChunkName: "pages/news/_uri" */))
const _50e2552f = () => interopDefault(import('../pages/tag/_uri.vue' /* webpackChunkName: "pages/tag/_uri" */))
const _2aeb3b11 = () => interopDefault(import('../pages/preview/_id/_slug.vue' /* webpackChunkName: "pages/preview/_id/_slug" */))
const _7075d18d = () => interopDefault(import('../pages/template/_id/_slug.vue' /* webpackChunkName: "pages/template/_id/_slug" */))
const _dd705616 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e9ed8da = () => interopDefault(import('../pages/_uri.vue' /* webpackChunkName: "pages/_uri" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authors",
    component: _434f34ee,
    name: "authors"
  }, {
    path: "/categories",
    component: _3c6b12cc,
    name: "categories"
  }, {
    path: "/search",
    component: _2d1ac5d8,
    name: "search"
  }, {
    path: "/author/:uri?",
    component: _ce5099d8,
    name: "author-uri"
  }, {
    path: "/category/:uri?",
    component: _07bfe241,
    name: "category-uri"
  }, {
    path: "/news/:uri?",
    component: _7be8518c,
    name: "news-uri"
  }, {
    path: "/tag/:uri?",
    component: _50e2552f,
    name: "tag-uri"
  }, {
    path: "/preview/:id?/:slug?",
    component: _2aeb3b11,
    name: "preview-id-slug"
  }, {
    path: "/template/:id?/:slug?",
    component: _7075d18d,
    name: "template-id-slug"
  }, {
    path: "/",
    component: _dd705616,
    name: "index"
  }, {
    path: "/:uri",
    component: _4e9ed8da,
    name: "uri"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
