import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
 
Vue.use(VueAwesomeSwiper, {
    slidesPerView: 'auto',
    spaceBetween: 10,
    freeMode: true,
    mousewheel: {
      enabled: true
    },
    breakpoints: {
      768: {
        spaceBetween: 30
      }
    }
})