import Vue from 'vue'

const showImageObserver = new IntersectionObserver(
    (entries, showImageObserver) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !entry.target.classList.contains('loaded')) {
    
                let path = entry.target.getAttribute('data-image');
                let folder = entry.target.getAttribute('data-folder');

                if (folder) {
                    path += '/' + folder;
                }

                let image = new Image();  
                image.onload = function() {
                    entry.target.style.backgroundImage = `url(${path})`;
                    entry.target.classList.add('loaded');
                } 
                image.src = path; 
    
                showImageObserver.unobserve(entry.target)
            }
        })
    }
)

Vue.directive('show-image', {
    bind: (el) => { 
        showImageObserver.observe(el)
    }
})