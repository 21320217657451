export const state = () => ({})
  
export const mutations = {}

export const actions = {
    get: function (page) {
        return axios.get(`/api/v1/app/insets`, {
                params: {
                    status: 'visible',
                    page: page,
                    sort: 'position|asc'
                }
        }).then(({ data }) => {
            if(data) {
                let newData = data.data.map((item) => {
                    let newItem = {
                        type: item.type,
                        position: item.position,
                        id: item.id
                    };
                    if(item.type == 'custom') {
                        newItem['left'] = item.left;
                        newItem['right'] = item.right;
                    } else if(item.type == 'categories'){
                        newItem['category_name'] = item.category.name;
                        newItem['category_uri'] = item.category.uri;
                    }
                        return newItem
                })
                return newData;
            }
            return {};
        })
    },
    share: function(data) {
        let newData = new FormData;
        for(let key in data) {
            newData.append(key, data[key]);
        }
        
        return axios.post(`/api/v1/app/inset_stat`, newData)
        .then(({ data }) => {
        })
    },
}