export const state = () => ({
    news: [],
    lastPage: 0,
    currentPage: 1,
    category: {},
    author: {},
    tag: {},
    pages: [{
        page: 1,
        last: 8,
        first: 0
    }],
    indexPerPage: 8,
    blocksAmount: 5,
    newsByCategory: {},
    reserve: [],
    recs: [],
    blocks: []
})
  
export const mutations = {
    setNews(state, news) {
        state.news.push(...news);
    },
    clearNews(state) {
        state.news = [];
    },
    setCategory(state, category) {
        state.category = category;
    },
    setAuthor(state, author) {
        state.author = author;
    },
    setTag(state, tag) {
        state.tag = tag;
    },
    setLastPage(state, lastPage) {
        state.lastPage = lastPage;
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage;
    },
    setPages(state, page) {
        state.pages.push(page)
    },
    clearPages(state) {
        state.pages = [{
            page: 1,
            last: 8,
            first: 0
        }];
    },
    setNewsByCategory(state, categoryURI, news) {
        state.newsByCategory[categoryURI] = news
    },
    clearNewsByCategory(state, categoryURI) {
        state.newsByCategory[categoryURI] = []
    },
    setBlocks(state, blocks) {
        state.blocks.push(blocks)
    },
    clearBlocks(state) {
        state.blocks = [];
    },
    addRecs(state, recs) {
        state.recs = recs
    },
    removeRecs(state, index) {
        state.recs.splice(index, 1)
    },
    clearRecs(state) {
        state.recs = []
    },
    addReserves(state, reserve) {
        state.reserve.push(...reserve)
    },
    removeReserves(state, params) {
        state.reserve.splice(params.index, params.length)
    },
    clearReserve(state) {
        state.reserve = []
    },
}

export const actions = {

    async getList({commit, dispatch, state}, params) {
        const data = await this.$axios.$get(`/api/v1/app/news`, {
            params : params
        })

        if (params.page == 1 && data.news.total == 0) {
            throw new Error({statusCode: 404, message: 'Сторінку не знайдено'});
        }

        function makeBlockObject(params) {
            return {
                id: params.block.id,
                name: params.block.name ? params.block.name : params.block.title,
                image: params.block.image,
                is_blog: params.block.is_blog,
                category: params.block.category ? {
                   name: params.block.category.name,
                } : null,
                uri: params.block.uri ? params.block.uri : params.block.href.split('/news/')[1],
                source: params.source
            }
        }

        if (params.page == 1 && data.category) {
            commit('setCategory', data.category)
        }

        if (params.page == 1 && data.author) {
            commit('setAuthor', data.author)
        }

        if (params.page == 1 && data.tag) {
            commit('setTag', data.tag)
        }

        commit('setLastPage', data.news.last_page)
        
        if (params.page != 1) {
            commit('setPages', {
                page: params.page,
                last: state.indexPerPage * data.news.current_page,
                first: state.indexPerPage * data.news.current_page - state.indexPerPage
            })
        }

        if (params.main) {
            let news = []

            const serverNews   = data.news.data
            const serverBlocks = data.blocks

            if (params.page == 1) {
                commit('clearBlocks')
                commit('clearReserve')
                commit('clearRecs')
            }

            // Считаем кол-во новостей, которые нужно обрезать (вычесть блоки)
            const lastSlice = serverNews.length - state.blocksAmount;

            commit('addReserves', serverNews.slice(lastSlice))
            
            let newBlocks = [];
            if (serverBlocks.length) {
                serverBlocks.forEach(async (block) => {
                    const position = block.position.split('.')[1] - 1

                    if (block['is_promo']) {
                        newBlocks[position] = makeBlockObject({
                            block: block, 
                            source: 'promo'
                        })
                    } else if (!params.except) {
                        newBlocks[position] = makeBlockObject({
                            block: block, 
                            source: 'design'
                        })
                    }
                })
            }

            if (params.except) {
                if (params.page == 1) {
                    await this.$axios.$get(`/recs.json`).then((data) => {
                        commit('addRecs', data.items)
                    }).catch(error => {})
                }
            } else {
                commit('clearRecs')
            }

            if (state.recs.length) {
                newBlocks.forEach(block => {
                    state.recs.forEach((rec,i) => {
                        if (block.id == rec.id) {
                            commit('removeRecs', i)
                        }
                    })
                })
            }

            for (let i = 0; i < state.blocksAmount; i++) {
                if (!newBlocks[i]) {
                    if (state.recs.length) {
                        newBlocks[i] = makeBlockObject({
                            block: state.recs[0], 
                            source: 'recs'
                        })
                        commit('removeRecs', 0)
                    } else {
                        if (state.reserve.length) {
                            newBlocks[i] = makeBlockObject({
                                block: state.reserve[0], 
                                source: 'block'
                            })

                            commit('removeReserves', {
                                index: 0,
                                length: 1
                            })
                        }
                    }
                }
            }

            commit('setBlocks', newBlocks)

            if (state.reserve.length) {
                const pushLength = state.reserve.length >= state.indexPerPage ? state.indexPerPage : state.reserve.length
                const remains = state.indexPerPage - pushLength

                if (pushLength < state.indexPerPage) {
                    news.push(...serverNews.slice(0, remains))
                }
                
                let reserves = [...state.reserve]
                news.push(...reserves.slice(0, pushLength))

                await commit('removeReserves', {
                    index: 0, 
                    length: pushLength
                })

                await commit('addReserves', serverNews.slice(remains, lastSlice))

            } else {
                news = serverNews.slice(0, lastSlice)
            }


            return news
        }

        return data.news.data;
    },

    async getListForSlider({commit, state}, params) {
        const data = await this.$axios.$get(`/api/v1/app/news`, {
            params : params
        })

        return data
    }

}

export const getters = {
    news(state) {
        return state.news;
    },
    category(state) {
        return state.category;
    },
    author(state) {
        return state.author;
    },
    tag(state) {
        return state.tag;
    },
    lastPage(state) {
        return state.lastPage;
    },
    currentPage(state) {
        return state.currentPage;
    },
    pages(state) {
        return state.pages;
    },
    indexPerPage(state) {
        return state.indexPerPage;
    },
    newsByCategory(state, categoryURI) {
        return state.newsByCategory[categoryURI]
    },
    blocks(state) {
        return state.blocks;
    },
    reserve(state) {
        return state.reserve;
    },
    recs(state) {
        return state.recs;
    }
}