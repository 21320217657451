import Vue from 'vue'

Vue.directive('datetime', {
    bind: (el, binding) => {
        let text;

        switch (binding.arg) {
            case 'feed':
                text = '';
                break;
            
            case 'DDMMYYYY':
                text = new Date(binding.value).toLocaleDateString(
                    'uk-UK',{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    });
                break;

            case 'HHMM':
                const today = new Date(),
                      date  = new Date(binding.value)

                if (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
                    text = Intl.DateTimeFormat('uk-UK', { 
                        hour: "numeric", 
                        minute: "numeric", 
                    }).format(date)
                } else {
                    if (binding.modifiers.mobile) {
                        if (date.getFullYear() == today.getFullYear()) {
                            text = new Date(date).toLocaleDateString(
                                'uk-UK', {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: "numeric", 
                                    minute: "numeric", 
                                });
                        } else {
                            text = new Date(date).toLocaleDateString(
                                'uk-UK', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: "numeric", 
                                    minute: "numeric", 
                                });
                        }
                    } else {
                        if (date.getFullYear() == today.getFullYear()) {
                            text = new Date(date).toLocaleDateString(
                                'uk-UK', {
                                    month: 'long',
                                    day: 'numeric',
                                    hour: "numeric", 
                                    minute: "numeric", 
                                });
                        } else {
                            text = new Date(date).toLocaleDateString(
                                'uk-UK', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: "numeric", 
                                    minute: "numeric", 
                                });
                        }
                    }
                }
                break;
                
                case 'DDMM':
                    text = new Date(binding.value).toLocaleDateString(
                        'uk-UK', {
                            month: 'long',
                            day: 'numeric',
                        });
                    break;
        
            default:
                text = new Date(binding.value).toLocaleDateString('uk-UK', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                break;
        }

        el.innerText = text
    }
})