//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        uri() {
            return this.$route.path
        },
        categories() {
            return this.$store.getters.categories
        },
        category() {
            return this.$store.getters['news/category']
        }
    },
    data() {
        return {
            isOpenMenu : false
        }
    },
    async mounted() {}
}
