//
//
//
//
//
//
//
//

import Header from '@/components/Header'
import SVGSprite from '@/components/SVGSprite'

export default {
    components: {
        Header,
        SVGSprite
    }
} 
