export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const SVGSprite = () => import('../../components/SVGSprite.vue' /* webpackChunkName: "components/s-v-g-sprite" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlock = () => import('../../components/blocks/Block.vue' /* webpackChunkName: "components/blocks-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlockCategory = () => import('../../components/blocks/BlockCategory.vue' /* webpackChunkName: "components/blocks-block-category" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlockFeed = () => import('../../components/blocks/BlockFeed.vue' /* webpackChunkName: "components/blocks-block-feed" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlockVideo = () => import('../../components/blocks/BlockVideo.vue' /* webpackChunkName: "components/blocks-block-video" */).then(c => wrapFunctional(c.default || c))
export const InsetsCurrency = () => import('../../components/insets/Currency.vue' /* webpackChunkName: "components/insets-currency" */).then(c => wrapFunctional(c.default || c))
export const InsetsHoroscope = () => import('../../components/insets/Horoscope.vue' /* webpackChunkName: "components/insets-horoscope" */).then(c => wrapFunctional(c.default || c))
export const InsetsJoke = () => import('../../components/insets/Joke.vue' /* webpackChunkName: "components/insets-joke" */).then(c => wrapFunctional(c.default || c))
export const InsetsQuote = () => import('../../components/insets/Quote.vue' /* webpackChunkName: "components/insets-quote" */).then(c => wrapFunctional(c.default || c))
export const InsetsVideo = () => import('../../components/insets/Video.vue' /* webpackChunkName: "components/insets-video" */).then(c => wrapFunctional(c.default || c))
export const InsetsWeather = () => import('../../components/insets/Weather.vue' /* webpackChunkName: "components/insets-weather" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal = () => import('../../components/modals/Modal.vue' /* webpackChunkName: "components/modals-modal" */).then(c => wrapFunctional(c.default || c))
export const RowsContent = () => import('../../components/rows/Content.vue' /* webpackChunkName: "components/rows-content" */).then(c => wrapFunctional(c.default || c))
export const RowsInset = () => import('../../components/rows/Inset.vue' /* webpackChunkName: "components/rows-inset" */).then(c => wrapFunctional(c.default || c))
export const SlidersCategorySlider = () => import('../../components/sliders/CategorySlider.vue' /* webpackChunkName: "components/sliders-category-slider" */).then(c => wrapFunctional(c.default || c))
export const SlidersLinkSlider = () => import('../../components/sliders/LinkSlider.vue' /* webpackChunkName: "components/sliders-link-slider" */).then(c => wrapFunctional(c.default || c))
export const SlidersVideoSlider = () => import('../../components/sliders/VideoSlider.vue' /* webpackChunkName: "components/sliders-video-slider" */).then(c => wrapFunctional(c.default || c))
export const ViewsDataItem = () => import('../../components/views/DataItem.vue' /* webpackChunkName: "components/views-data-item" */).then(c => wrapFunctional(c.default || c))
export const ViewsPseudoDataItem = () => import('../../components/views/PseudoDataItem.vue' /* webpackChunkName: "components/views-pseudo-data-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
