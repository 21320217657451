//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['error'],
    layout: 'error'
}
